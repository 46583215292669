import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

import city1 from "../../assets/img/city1.png";
import city2 from "../../assets/img/city2.png";
import city3 from "../../assets/img/city3.png";
import city4 from "../../assets/img/city4.png";

import imgComino from "../../assets/img/comino.jpg";
import imgDinglcliff from "../../assets/img/DinglCliff.jpg";
import imgGoldenBay from "../../assets/img/GoldenBay.jpg";
import imgGozo from "../../assets/img/Gozo.jpg";
import imgMarsaxlokk from "../../assets/img/Marsaxlokk.jpg";
import imgMdina from "../../assets/img/Mdina.jpg";
import imgMellieha from "../../assets/img/Mellieha.jpg";
import imgPopeyeVillage from "../../assets/img/PopeyeVillage.jpg";
import imgSaintJulians from "../../assets/img/SaintJulians.jpg";
import imgValletta from "../../assets/img/Valletta.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import groupServices from "../../assets/img/group-service.png";
import DOMPurify from 'dompurify';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 501 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};
const allItems = [
  {
    src: imgComino,
    key: 1,
    heading: "Comino",
    text: "10 routes to/from this city",
  },
  {
    src: imgDinglcliff,
    key: 2,
    heading: "Dingli Cliff",
    text: "10 routes to/from this city",
  },
  {
    src: imgGoldenBay,
    key: 3,
    heading: "Golden Bay",
    text: "10 routes to/from this city",
  },
  {
    src: imgGozo,
    key: 4,
    heading: "Gozo",
    text: "10 routes to/from this city",
  },
  {
    src: imgMarsaxlokk,
    key: 5,
    heading: "Marsaxlokk",
    text: "10 routes to/from this city",
  },
  {
    src: imgMdina,
    key: 6,
    heading: "Mdina",
    text: "10 routes to/from this city",
  },
  {
    src: imgMellieha,
    key: 7,
    heading: "Mellieha",
    text: "10 routes to/from this city",
  },
  {
    src: imgPopeyeVillage,
    key: 8,
    heading: "Popeye Village",
    text: "10 routes to/from this city",
  },
  {
    src: imgSaintJulians,
    key: 9,
    heading: "Saint Julians",
    text: "10 routes to/from this city",
  },
  {
    src: imgValletta,
    key: 10,
    heading: "Valletta",
    text: "10 routes to/from this city",
  },
];
const CitySections = (props) => {
  const [siteSettings, setSiteSettings] = useState(null);
  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])

  const sanitizedDiscovermaltaMainhead = DOMPurify.sanitize(siteSettings?.['discovermalta_mainhead']);

  const { bg } = props;
  return (
    <div className="main-city-section" style={{ backgroundColor: bg }}>
      <div className="section-city">
        <Container fluid style={{ padding: 0 }}>
          <div className="city-titlebar text-center">
            {/* <h2
              className="heading-discover heading-city routes-color"
              data-aos="fade-up"
              data-aos-duration="1000"
            >

              Rent a Car Today and Embark Your
              <br />
              <span> Automate Adventure</span>
            </h2> */}
            <h2
              className="heading-discover heading-city routes-color p-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              dangerouslySetInnerHTML={{ __html: sanitizedDiscovermaltaMainhead }}
            >
{/*               
              Rent a Car Today and Embark Your
              <br />
              <span> Automate Adventure</span> */}
            </h2>
          </div>
          <div className="carousel-city py-3">
          <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              //   autoPlay={true}
              autoPlaySpeed={2500}
              shouldResetAutoplay={false}
              keyBoardControl={true}
              autoPlayInterval={2500}
              interval={2500}
              transitionDuration={500}
              containerClass="carousel-container"
              //  containerClass="carousel-container justify-content-center"
              //   removeArrowOnDeviceType={["superLargeDesktop","desktop","tablet", "mobile"]}
              removeArrowOnDeviceType={["tablet"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              pauseOnHover={true}
            >
              {allItems?.map((items, i) => {
                return (
                  <div key={i} className="route-box ">
                    <div className="dimg-box">
                      <img src={items.src} alt="" className="maltaAdventure" style={{
                        width: "100%",
                        
                        height: "320px",
                        objectFit: "cover",
                        display: "block",
                        margin: "0 auto",
                      }} />
                      <div className="route-content">
                        <div>
                          <h5>{items.heading}</h5>
                          {/* <p className="mb-0">{items.text}</p> */}
                        </div>
                        <a href="#" style={{ color: "#EE333A" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="13"
                            viewBox="0 0 19 13"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_640_397)">
                              <path
                                d="M11.9688 1.03906L17.0954 6.06509L11.9688 11.0911"
                                stroke="url(#paint0_linear_640_397)"
                                stroke-width="2.01041"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1.67773 6.06543H14.7856"
                                stroke="url(#paint1_linear_640_397)"
                                stroke-width="2.01041"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_640_397"
                                x1="11.9688"
                                y1="6.06508"
                                x2="14.913"
                                y2="6.51445"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9D0208" />
                                <stop offset="1" stop-color="#D00000" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_640_397"
                                x1="1.67773"
                                y1="6.56543"
                                x2="2.14778"
                                y2="8.40931"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9D0208" />
                                <stop offset="1" stop-color="#D00000" />
                              </linearGradient>
                              <clipPath id="clip0_640_397">
                                <rect width="19" height="13" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </Container>
      </div>
      
    </div>
  );
};

export default CitySections;
