import Swal from 'sweetalert2';
import React , { useEffect, useState } from "react";
import { Input, Button, Col, Container, Row } from "reactstrap";
import footerLogo from "../assets/img/carwhitelogo.jpg";
import DOMPurify from 'dompurify';
import { createNewsletter } from "../Api";
import BookingModal from './GeneralComponents/BookingModal'; // Adjust the path if necessary
import { useLocation } from "react-router-dom";


const Footer = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  const [siteSettings, setSiteSettings] = useState(null);
  const [newsletterEmail, setNewsletterEmail] = useState(null);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Parse URL parameters and update state variables
    const params = new URLSearchParams(window.location.search);
    const codeParam = params.get("code");
    const emailParam = params.get("email");
    if (codeParam && emailParam) {

      localStorage.setItem("codeParam", codeParam)
      localStorage.setItem("emailParam", emailParam)
      window.location.href = window.location.origin     

    }
  }, []);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));

    if(localStorage.getItem("codeParam") != null){
      // console.log("Reach inside")
      setCode(localStorage.getItem("codeParam"));
      setEmail(localStorage.getItem("emailParam"));
      setTimeout(() => {
        
      localStorage.removeItem("codeParam");
      localStorage.removeItem("emailParam");
      }, 1000);

      setTimeout(() => {
        document.getElementById("manageMyBookinBtn").click()
        setTimeout(() => {
                  document.getElementById("manageMyBookingSubmit").click()

        }, 500);

      }, 500);    }

    
  }, []); // Empty dependency array to run the effect only once when the component mounts
  

  const handleEmailChange = (e) => {
    setNewsletterEmail(e.target.value);
  };

  const handleNewsletterSubmit = () => {
    createNewsletter(newsletterEmail)
    .then((response) => {
      Swal.fire({
        icon: 'success',
        title: "Successfully Subscribed",
        text: 'You have been subscribed!',
      });
      setNewsletterEmail('');
    })
   
  };
  


  const sanitizedDevelopedBy = DOMPurify.sanitize(siteSettings?.['developed_by'] || '');


  return (
    <div className="footer-section text-light">
      <div className="page-footer">
        <Container>
          <div className="footer-menus">
            <Row>
              <Col lg="4" md="4" xs="12">
                <div className="footer-one">
                  <div className="footer-one-text">
                    <div className="footer-one-logo">
                      <img src={footerLogo} alt="" />
                    </div>
                    <p className="mb-2">
                    Automate Car Rental is a leading car rental company in Automate, offering a wide range of vehicles for both short- and long-term rentals. We are committed to providing our customers with a convenient, affordable, and enjoyable car rental experience.
                    </p>
                    
                   
                  </div>
                  <br />

                  <Button color="primary" className="px-3 py-2 mt-2" onClick={toggleModal} id="manageMyBookinBtn" >
                    Manage My Booking
                  </Button>
                  <BookingModal isOpen={isOpen} toggle={toggleModal} code={code} email={email}/>
                  
                  {/* <a href="https://adminqueencar.soundgallery.pk/verify-link" >
                    <Button color="primary" className="px-3 py-2 mt-2" >
                        Manage My Booking
                    </Button>
                    </a> */}
                </div>
              </Col>
              <Col lg="4" md="4" xs="12">
                <div className="footer-two">
                  <div className="footer-two-text">
                    <h4 className="footer-title">
                      <span>About Us</span>
                    </h4>
                    <ul className="footer-two-menu">
                    <li><a href={`tel:${siteSettings?.['site_phone']}`}>{siteSettings?.['site_phone']}</a></li>

                      {/* <li>{siteSettings?.['site_location']}</li> */}
                      <li><a href={`https://www.google.com/maps/place/${encodeURIComponent(siteSettings?.['site_location'])}`} target="_blank" rel="noopener noreferrer">{siteSettings?.['site_location']}</a></li>

                      <li>Our business hours are:

                       <br /> Mon-Fri: 09:00 - 18:00
                       <br /> Sat: 09:00 - 13:00
                       <br /> Sun: Closed
                      
                       </li>
                      <li>
                        {" "}
                        <a href="/ourpolicy">Privacy and Policy </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/terms-and-condition">Terms and Conditions </a>
                      </li>


                    
                    </ul>
                    <ul className="social-menu">
                      <li>
                        <a href={siteSettings?.['site_facebook']}>
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                      <a href={`https://wa.me/${siteSettings?.['site_whatsapp']}`}>
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    
                      {/* <li>
                        <a href="#">
                          <i className="fa fa-youtube-play"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="4" xs="12">
                <div className="footer-three">
                  <div className="footer-three-text">
                    <h4 className="footer-title"><span>Newsletter</span></h4>
                    <p>
                      Stay up to date with the latest news, announcements and articles.
                    </p>
                    <div className="footer-contact">
                    <Input placeholder="Insert Your Email"  className="footer-field" name="email"  value={newsletterEmail}  onChange={handleEmailChange} />
                      {/* <input placeholder="Email i'd" /> */}
                      <Button color="primary" className="footer-submit" onClick={handleNewsletterSubmit}>
                        SUBSCRIBE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="footer-copyright">
        <Container>
          <div className="footer-copyright-text text-center">
          <p className="mb-0">
            © Automate Car Rental - 2024 | All Right Reserved | Developed With <i className="fa fa-heart"></i>{' '}
            <span dangerouslySetInnerHTML={{ __html: sanitizedDevelopedBy }}></span>
          </p>

            
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
