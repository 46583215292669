import React, {  useEffect, useState } from "react";
import Header from "../components/Header";
import discover from "../assets/img/discover-malta.png";
import bgImgdiscover from "../assets/img/discovermalta.jpg";

import PageTitle from "../components/PageTitle";
import CitySections from "../components/Home/CitySections";
import Footer from "../components/Footer";
import { Button, Col, Container, Row } from "reactstrap";
import carImg from "../assets/img/ezgif.png";
import travel1 from "../assets/img/travel1.png";
import travel2 from "../assets/img/travel2.png";
import travel3 from "../assets/img/travel3.png";
import travel4 from "../assets/img/travel4.png";
import backpack1 from "../assets/img/backpack-08.png";
import backpack2 from "../assets/img/backpack2.jpg";
import backpack3 from "../assets/img/backpack3.jpg";
import backpack4 from "../assets/img/backpack4.jpg";

import traveling1 from "../assets/img/traveling1-07.png";
// import traveling1 from "../assets/img/traveling1.png";
import traveling2 from "../assets/img/malta-05.png";

import travel1of1 from "../assets/img/traveling1-1.png";
// import traveling2 from "../assets/img/traveling2.png";
import travel2of1 from "../assets/img/traveling2-1.png";
import GallerySection from "../components/Home/GallerySection";
import PremiumSection from "../components/Home/PremiumSection";
import PickUpForm from "../components/Home/PickUpForm";
import Faq from "../components/Fleet/Faq";
import { Link } from 'react-router-dom';

import Carousel from "react-multi-carousel";

import logo1 from "../assets/img/carlogo01.webp";
import logo2 from "../assets/img/carlogo02.webp";
import logo3 from "../assets/img/carlogo03.webp";
import logo4 from "../assets/img/carlogo04.webp";
import logo5 from "../assets/img/carlogo05.webp";
import logo6 from "../assets/img/carlogo06.webp";
import logo7 from "../assets/img/carlogo07.webp";
import logo8 from "../assets/img/carlogo08.webp";
import logo9 from "../assets/img/carlogo09.webp";
import logo10 from "../assets/img/carlogo10.webp";
import logo11 from "../assets/img/carlogo11.webp";
import portviewImg from "../assets/img/portview.webp";
import dungorgImg from "../assets/img/DunGorg.webp";
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const Discover = () => {

  const [siteSettings, setSiteSettings] = useState(null);
  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])
  const sanitizedDiscovermaltaSecondhead = DOMPurify.sanitize(siteSettings?.['discovermalta_secondhead']);
  const sanitizedIsland1head = DOMPurify.sanitize(siteSettings?.['discovermalta_island1head']);
  const sanitizedIsland2head = DOMPurify.sanitize(siteSettings?.['discovermalta_island2head']);


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const navigate = useNavigate();


  const handleChangeFleet = () => {
    // Navigate to the "/FleetView" route
    navigate('/FleetView');
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };


  const handleChangeLongTerm = () => {
    // Navigate to the "/FleetView" route
    navigate('/longtermform');
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  return (
    <div className="page-discover">
      <Header />
     <PageTitle
        heading="Discover Automate"
        bgImg={[bgImgdiscover]}
        rotationInterval={2000}
        gap="180px 0"
      />
      <CitySections bg="#fff" />
      <div className="search-section d-none">
        <Container fluid >
          <div className="search-content ">
            <h4>Your recent searches</h4>
            <Row>
              <Col lg="3" md="6" xs="12">
                <div className="search-content-info d-flex">
                  <div className="search-img">
                    <img src={carImg} />
                  </div>
                  <div>
                    <p className="mb-0">Toyota Aygo</p>
                    <small>325 cars</small>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6" xs="12">
                <div className="search-content-info d-flex">
                  <div className="search-img">
                    <img src={carImg} />
                  </div>
                  <div>
                    <p className="mb-0">Toyota Aygo</p>
                    <small>325 cars</small>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6" xs="12">
                <div className="search-content-info d-flex">
                  <div className="search-img">
                    <img src={carImg} />
                  </div>
                  <div>
                    <p className="mb-0">Toyota Aygo</p>
                    <small>325 cars</small>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6" xs="12">
                <div className="search-content-info d-flex">
                  <div className="search-img">
                    <img src={carImg} />
                  </div>
                  <div>
                    <p className="mb-0">Toyota Aygo</p>
                    <small>325 cars</small>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="section-travel">
        <Container>
          <Row>
            <Col md="11" lg="11" xs="12">
              <div className="travel-content">
                <div className="travel-heading">
                  {/* <h2 data-aos="fade-up">
                    <span>Fall into </span>travel */}
                    <h2
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{ __html: sanitizedDiscovermaltaSecondhead }}
                  >
           
                  </h2>
                  <p className="mb-0">
                  {siteSettings?.['discovermalta_secondpara']}

                  
                    {/* Going somewhere to celebrate this season? Whether you're
                    going home or somewhere to <br /> roam, we've got the travel
                    tools to get you to your destination. */}
                  </p>
                </div>
              </div>
            </Col>
            <Col md="1" lg="1" xs="12" className="display-btn-center">
              <div className="travel-btn">
                {/* <Button color="secondary">See All</Button> */}
              </div>
            </Col>
          </Row>

          <div className="travel-images-section">
            <Row>
              <Col md="12" lg="5" xs="12">
                <div className="travel-hover-heading text-light">
                  <h4>Backpacking Automate</h4>
                  <p className="mt-3">
                    Traveling is a unique experience as it's the best way to
                    unplug from the pushes and pulls of daily life. It helps us
                    to forget about our problems, frustrations, and fears at
                    home. During our journey, we experience life in different
                    ways. We explore new places, cultures, cuisines, traditions,
                    and ways of living.
                  </p>
                
                  <Button color="secondary" className="mt-1" onClick={handleChangeFleet}>
                    Rent Car
                </Button>
                </div>
              </Col>
              <Col md="12" lg="7" xs="12">
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <div className="travel-img">
                      <img src={backpack1} style={{ width: "100%" }} />
                    </div>
                    <div className="travel-img">
                      <img src={backpack2} style={{ width: "100%" }} />
                    </div>
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <div className="travel-img tr-mg">
                      <img src={backpack3} style={{ width: "100%" }} />
                    </div>
                    <div className="travel-img">
                      <img src={backpack4} style={{ width: "100%" }} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="content-section">
        <Container>
          <div className="content-text-inner">
            <Row style={{ alignItems: "center", marginBottom: "100px" }}>
              <Col md="12" lg="6" xs="12">
                <div className="content-img ">
                  <div className="contn-mn-img text-center">
                  <Link  to="/FleetView"><img src={traveling1} /></Link>
                  </div>
                  {/* <a href="#" className="content-contact"> */}
                  <Link  to="/FleetView" className="content-contact">
                    <img src={travel1of1} />
                  </Link>
                  {/* </a> */}
                </div>
              </Col>
              <Col md="12" lg="6" xs="12">
                <div className="content-content">
                  <h2 style={{ marginBottom: 0 }} data-aos="fade-up"
                  dangerouslySetInnerHTML={{ __html: sanitizedIsland1head }}
                  >
                  </h2>
                  {/*
                  <span>Lorem ipsum </span>zxcv
                   <h2 className="dolar-color" data-aos="fade-up">
                    dolor sit amet
                  </h2> */}
                  <p className="mt-4">                  
                    {siteSettings?.['discovermalta_island1para']}

                  </p>
                </div>
              </Col>
            </Row>
            <Row className="column-reverse" style={{ alignItems: "center" }}>
              <Col md="12" lg="6" xs="12">
                <div className="content-content">
                  <h2 style={{ marginBottom: 0 }} data-aos="fade-up"
                  dangerouslySetInnerHTML={{ __html: sanitizedIsland2head }}
                  >
                  </h2>
                  {/* <h2 style={{ marginBottom: 0 }} data-aos="fade-up">
                    <span>Lorem ipsum </span>
                  </h2>
                  <h2 className="dolar-color" data-aos="fade-up">
                    dolor sit amet
                  </h2> */}
                  <p className="mt-4">
                    {siteSettings?.['discovermalta_island2para']}
                  </p>
                </div>
              </Col>
              <Col md="12" lg="6" xs="12">
                <div className="content-img ">
                  <div className="contn-mn-img text-center">
                    <img src={traveling2} />
                  </div>
                  <a href="#" className="content-contact">
                    <img src={travel2of1} />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* <GallerySection /> */}
      <Container> 
      <div className="faqs-heading">
          <h3>Our Partners</h3>
        </div>
        </Container>
      <div className="logo-animated">
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          showDots={false}
          customTransition="all .5"
          transitionDuration={100}
          containerClass="carousel-container"
          
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-20-px"
        >
          <div></div>
          <div>
            <a href="https://www.portviewmalta.com/">
              <img src={portviewImg} alt="" />
            </a>
          </div>
          <div>
            <a href="https://www.dungorgguesthouse.com/">
              <img src={dungorgImg} alt="" />
            </a>
          </div>
          {/* <div>

            <a href="#">
              <img src={logo1} alt="" />
            </a>

          </div>
          <div>
            <a href="#">
              <img src={logo2} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo3} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo4} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo5} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo6} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo7} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo8} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo9} alt="" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={logo11} alt="" />
            </a>
          </div> */}


          
        </Carousel>
      </div>
      <Faq />
      <PremiumSection />
      <PickUpForm btnText="Check Availability" boxShadow="5px 5px 5px #ccc" />
      <div className="text-center btn-lease-discover">
        <Button color="primary" data-aos="fade-up" onClick={handleChangeLongTerm}>
          Long Term Lease
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Discover;
