import React, { useState, useEffect }  from "react";
import { Card, CardFooter, Col, Container, Row } from "reactstrap";
import star from "../../assets/img/star.png";
import client1 from "../../assets/img/test1.png";
import client2 from "../../assets/img/test2.png";
import client3 from "../../assets/img/test3.png";
import quote from "../../assets/img/quotes.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 501 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};
const allItems = [
  {
    src: client1,
    key: 1,
    text: "Kenvin Martin",
  },
  {
    src: client2,
    key: 2,
    text: "Mike hardson",
  },
  {
    src: client3,
    key: 3,
    text: "Jessica Brown",
  },
  {
    src: client1,
    key: 4,
    text: "Kenvin Martin",
  },
  {
    src: client2,
    key: 5,
    text: "Mike hardson",
  },
  {
    src: client3,
    key: 6,
    text: "Jessica Brown",
  },
];
const Testimonial = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const [siteSettings, setSiteSettings] = useState(null);


  useEffect(() => {
    setTimeout(() => {
      
    
    const tempSetting = localStorage.getItem('site_settings');
    
      if (tempSetting) {
        const parsedSettings = JSON.parse(tempSetting);
        setSiteSettings(parsedSettings);
        setTestimonialData( JSON.parse(parsedSettings['testimonial']));
      

      }
    }, 100);
  }, []);
  return (
    <div className="testimonial-section">
      <Container>
        <div className="our-testimonials">
          <div className="testimonials-heading">
            <Row>
              <Col lg="6" md="6" xs="12">
                <div
                  className="test-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h4 data-aos="fade-up">Our testimonials</h4>
                  <h3
                    className="heading-discover heading-test"
                    data-aos="fade-up"
                  >
                    What They're Talking <br />
                    <span>About Automate</span>
                  </h3>
                </div>
              </Col>
              <Col lg="6" md="6" xs="12" className="middle-text">
                <div className="test-content">
                  <p>
                    Discover the Rave Reviews for Crown Auto Services - Unveiling the Praise!
                  </p>
                </div>
              </Col>
            </Row>
            <div className="IndicatorCarousel">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                //   autoPlay={true}
                autoPlaySpeed={7500}
                shouldResetAutoplay={false}
                keyBoardControl={true}
                autoPlayInterval={7500}
                interval={7500}
                transitionDuration={500}
                containerClass="carousel-container"
                //  containerClass="carousel-container justify-content-center"
                removeArrowOnDeviceType={[
                  "superLargeDesktop",
                  "desktop",
                  "tablet",
                  "mobile",
                ]}
                // removeArrowOnDeviceType={["tablet"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                pauseOnHover={true}
              >



              
                  {testimonialData?.map((items, i) => {
                  return (
                    <Card key={i} body className="card-client">
                      <div className="testimonials-client text-center">
                        <img src={quote} className="img-quote" alt="" />
                        <p>
                          {items.desc}
                        </p>
                        <img src={star} alt="" />
                      </div>
                      <div className="client-view">
                        <img src={allItems[i].src} alt="" />
                        <div>
                          <p className="mb-0">{items.name}</p>
                          <span>{items.designation}</span>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              
                
              </Carousel>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonial;
